import { API_BASES } from "@lib/constants";
import { GlobalRef } from "@lib/globalManager";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getConfig } from "config";
import { CONTENT_TYPES } from "../constants/urls";
import logger from "../logger";

export const axiosInstance = axios.create({});

export const anonAccessToken = new GlobalRef("anonAccessTokenManager");

interface InternalAxiosRequestConfigWithNoAuthorization extends InternalAxiosRequestConfig {
	noAuthorization?: boolean;
}

// Create a flag to indicate whether a token refresh is in progress
let isRefreshing = false;

// Max number of retries
const UNAUTHORIZED_MAX_RETRIES = 1; // ! 3

// Is Serverside
const IS_SERVER = typeof window === "undefined";

// Errors to watch out for
const ERRORS_TO_RETRY = [
	401, // Unauthorized
	403, // Forbidden
];

// Add a response interceptor
axiosInstance.interceptors.response.use(undefined, async (error) => {
	if (!IS_SERVER && error.config && error.response && ERRORS_TO_RETRY.includes(error.response.status)) {
		const originalRequest = error.config;

		// Add a retryCount property to the originalRequest object
		originalRequest.retryCount = originalRequest.retryCount || 0;

		// If a token refresh is not already in progress and the maximum number of attempts has not been reached
		if (!isRefreshing && originalRequest.retryCount < UNAUTHORIZED_MAX_RETRIES) {
			isRefreshing = true;
			originalRequest.retryCount++;

			// Get a new access token
			const newAccessToken = await anonAccessToken.getAccessToken();

			// Set the new access token in the Authorization header
			axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

			isRefreshing = false;

			// Repeat the last failed request
			originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
			return axiosInstance(originalRequest);
		}

		// If a token refresh is in progress, reject the promise
		return Promise.reject(error);
	}

	// If the response status is not 401 or 405, reject the promise with the error
	return Promise.reject(error);
});

// Enforce no authorization even when a default Authorization header is set
axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfigWithNoAuthorization) => {
	const withNoAuthorization = request.noAuthorization;

	if (withNoAuthorization && request.headers.hasAuthorization()) {
		delete request.headers.Authorization;
	}

	return request;
});

const config = getConfig();
const api4BaseUrl = config.API4_BASE_URL;
const api4InternalBaseUrl = config.API4_INTERNAL_BASE_URL;
const env = config.ENV_NAME;
const searchBaseUrl = config.SEARCH_BASE_URL;
const isTestEnv = process.env.NODE_ENV === "test";

const userAgent =
	env === "development" ?
		"" :
		env === "feature" ?
			process.env.GIT_COMMIT :
			process.env.GIT_TAG;

const isClient = typeof window !== "undefined";

export const getBaseUrl = (apiBase?: string) => {
	switch (apiBase) {
		case API_BASES.api4:
			if (!isClient) {
				return api4InternalBaseUrl;
			}
			return api4BaseUrl;
		case API_BASES.search:
			return searchBaseUrl;
		default:
			return "";
	}
};

interface requestArgsTypeWithAccessToken extends requestArgsType {
	accessToken?: string;
}

const buildHeaders = async (requestArgs: requestArgsTypeWithAccessToken) => {
	const headers: Record<string, string | boolean> = {
		"Content-Type": requestArgs.contentType ?? CONTENT_TYPES.applicationJson,
	};

	if (!isClient) {
		headers["User-Agent"] = "beatport-next/" + userAgent;
	}

	if (requestArgs.location?.realIp && requestArgs.location?.countryCode) {
		headers["X-Bp-Real-Ip"] = requestArgs.location.realIp;
		headers["X-Bp-Country-Code"] = requestArgs.location.countryCode;
	}

	let requestHeaders = { ...headers };

	if (requestArgs.headers) {
		requestHeaders = {
			...requestHeaders,
			...requestArgs.headers,
		};
	}

	if (!requestArgs.noAuthorization) {
		requestArgs.accessToken = requestArgs.accessToken || "";

		// Ensure that we are not calling api-internal on client-side req
		if (!isClient && !requestArgs.accessToken) {
			requestArgs.accessToken = await anonAccessToken.getAccessToken();
		}
	}

	if (requestArgs.accessToken) {
		requestHeaders = {
			...requestHeaders,
			Authorization: "Bearer " + requestArgs.accessToken,
		};
	}

	return requestHeaders;
};

export const getRequest = async (
	requestArgs: requestArgsTypeWithAccessToken,
	withErrorHandling = true,
) => {
	const requestConfig = {
		method: "get",
		url: requestArgs.url,
		baseURL: getBaseUrl(requestArgs.apiBase),
		headers: await buildHeaders(requestArgs),
		params: requestArgs.params,
		withCredentials: requestArgs.withCredentials,
		responseType: requestArgs.responseType ? requestArgs.responseType : "json",
		noAuthorization: requestArgs.noAuthorization,
	} as AxiosRequestConfig;

	if (!withErrorHandling && !isTestEnv) {
		return axiosInstance(requestConfig).then((response: AxiosResponse) =>
			handleSuccess(response),
		);
	}

	return axiosInstance(requestConfig)
		.then((response: AxiosResponse) => handleSuccess(response))
		.catch((error: AxiosError) => handleError(error));
};

export const putRequest = async (
	requestArgs: requestArgsTypeWithAccessToken,
	withErrorHandling = true,
) => {
	const requestConfig = {
		method: "put",
		url: requestArgs.url,
		data: requestArgs.data,
		baseURL: getBaseUrl(requestArgs.apiBase),
		headers: await buildHeaders(requestArgs),
		params: requestArgs.params,
		withCredentials: requestArgs.withCredentials,
		responseType: requestArgs.responseType ? requestArgs.responseType : "json",
		noAuthorization: requestArgs.noAuthorization,
	} as AxiosRequestConfig;

	if (!withErrorHandling && !isTestEnv) {
		return axiosInstance(requestConfig).then((response: AxiosResponse) =>
			handleSuccess(response),
		);
	}

	return axiosInstance(requestConfig)
		.then((response: AxiosResponse) => handleSuccess(response))
		.catch((error: AxiosError) => handleError(error));
};

export const patchRequest = async (
	requestArgs: requestArgsTypeWithAccessToken,
	withErrorHandling = true,
) => {
	const requestConfig = {
		method: "patch",
		url: requestArgs.url,
		data: requestArgs.data,
		baseURL: getBaseUrl(requestArgs.apiBase),
		headers: await buildHeaders(requestArgs),
		params: requestArgs.params,
		withCredentials: requestArgs.withCredentials,
		responseType: requestArgs.responseType ? requestArgs.responseType : "json",
		noAuthorization: requestArgs.noAuthorization,
	} as AxiosRequestConfig;

	if (!withErrorHandling && !isTestEnv) {
		return axiosInstance(requestConfig).then((response: AxiosResponse) =>
			handleSuccess(response),
		);
	}

	return axiosInstance(requestConfig)
		.then((response: AxiosResponse) => handleSuccess(response))
		.catch((error: AxiosError) => handleError(error));
};

export const postRequest = async (
	requestArgs: requestArgsTypeWithAccessToken,
	withErrorHandling = true,
) => {
	const requestConfig = {
		method: "post",
		url: requestArgs.url,
		data: requestArgs.data,
		baseURL: getBaseUrl(requestArgs.apiBase),
		headers: await buildHeaders(requestArgs),
		params: requestArgs.params,
		withCredentials: requestArgs.withCredentials,
		responseType: requestArgs.responseType ? requestArgs.responseType : "json",
		noAuthorization: requestArgs.noAuthorization,
	} as AxiosRequestConfig;

	if (!withErrorHandling && !isTestEnv) {
		return axiosInstance(requestConfig).then((response: AxiosResponse) =>
			handleSuccess(response),
		);
	}

	return axiosInstance(requestConfig)
		.then((response: AxiosResponse) => handleSuccess(response))
		.catch((error: AxiosError) => handleError(error));
};

export const deleteRequest = async (
	requestArgs: requestArgsTypeWithAccessToken,
	withErrorHandling = true,
) => {
	const requestConfig = {
		method: "delete",
		url: requestArgs.url,
		data: requestArgs.data,
		baseURL: getBaseUrl(requestArgs.apiBase),
		headers: await buildHeaders(requestArgs),
		params: requestArgs.params,
		withCredentials: requestArgs.withCredentials,
		responseType: requestArgs.responseType ? requestArgs.responseType : "json",
		noAuthorization: requestArgs.noAuthorization,
	} as AxiosRequestConfig;

	if (!withErrorHandling && !isTestEnv) {
		return axiosInstance(requestConfig)
			.then((response: AxiosResponse) => handleSuccess(response))
			.catch((error: AxiosError) => handleError(error));
	}

	return axiosInstance(requestConfig).then((response: AxiosResponse) =>
		handleSuccess(response),
	);
};

const handleSuccess = (response: AxiosResponse): ApiResponse => {
	return {
		success: true,
		status: response.status,
		statusText: response.statusText,
		data: response.data,
		headers: response.headers,
	};
};

const handleError = (error: AxiosError): ApiResponse => {
	const is404 = error.response?.status === 404;

	if (isTestEnv && is404) {
		logger.error(
			"ERROR: The following endpoint was not found - Maybe you forgot to mock it?",
			"[", error.config?.method?.toUpperCase(), "]",
			error.config?.url,
		);
	}

	if (error.response) {
		return {
			success: false,
			status: error.response.status,
			statusText: error.response.statusText,
			data: error.response.data,
		};
	} else {
		return {
			success: false,
			status: 500,
			statusText: error.message,
			data: {},
		};
	}
};
