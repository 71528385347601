import Error, { ErrorTypes } from "@components/pages/error/Error";
import dynamic from "next/dynamic";
import React, { Component } from "react";

const MainLayout = dynamic(() => import("@layouts/MainLayout"), {
	loading: () => <p>Loading...</p>,
});

interface ErrorProps {
	children: React.ReactNode;
}

interface ErrorState {
	hasError: boolean;
	error: any;
}

class ErrorBoundary extends Component<ErrorProps, ErrorState> {
	constructor(props: ErrorProps) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;
		const isDev = process.env.NODE_ENV === "development";

		if (hasError && !isDev) {
			return (
				<MainLayout title="Something went wrong">
					<Error
						errorType={ErrorTypes.General}
						onTryAgain={() => this.setState({ hasError: false })}
					/>
				</MainLayout>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
