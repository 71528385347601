import { getResetDownload } from "@lib/network/library";
import { Track } from "@models/track";
import React from "react";
import {
	ActionTypes,
	ADD_TRACKS_TO_DOWNLOAD,
	ADD_TRACKS_TO_PLAYLIST,
	CLEAR_DOWNLOAD_QUEUE,
	CLEAR_PLAYLIST_QUEUE,
	CollectionActions,
	SET_DOWNLOAD_QUEUE,
	SET_PLAYLIST_QUEUE,
} from "./types";
import logger from "../../logger";

export const createActions = (
	dispatch: React.Dispatch<ActionTypes>,
): CollectionActions => {
	return {
		clearPlaylistQueue: (): void => {
			dispatch({
				type: CLEAR_PLAYLIST_QUEUE,
			});
		},
		clearDownloadQueue: (): void => {
			dispatch({
				type: CLEAR_DOWNLOAD_QUEUE,
			});
		},
		setPlaylistQueue: (tracks: Track[]): void => {
			dispatch({
				type: SET_PLAYLIST_QUEUE,
				payload: {
					tracks: tracks,
				},
			});
		},
		setDownloadQueue: (tracks: Track[]): void => {
			dispatch({
				type: SET_DOWNLOAD_QUEUE,
				payload: {
					tracks: tracks,
				},
			});
		},
		addTrackToDownload: async (
			track: Track,
			accessToken: string,
		): Promise<void> => {
			try {
				if (!track) {
					return;
				}
				await getResetDownload({
					order_item_download_id: track.order_item_download_id,
					accessToken,
				});
				dispatch({
					type: ADD_TRACKS_TO_DOWNLOAD,
					payload: {
						track: track,
					},
				});
			} catch (error) {
				logger.error(error);
			}
		},
		addTracksToPlaylist: (tracks: Track[]): void => {
			dispatch({
				type: ADD_TRACKS_TO_PLAYLIST,
				payload: {
					tracks: tracks,
				},
			});
		},
	};
};
