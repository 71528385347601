import { forwardRef, HTMLAttributes } from "react";
import { cls } from "src/lib/css";
import { ButtonStyled } from "./Button.style";
type ButtonType =
	| "primary"
	| "outline"
	| "outline-secondary"
	| "tertiary"
	| "link"
	| "primary-link"
	| "danger"
	| "secondary"
	| "transparent"
	| "transparent-danger";

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	children: JSX.Element | string;
	type?: ButtonType;
	submit?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	width?: "100" | "75" | "50" | "25";
	squared?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ children, type, submit, disabled, onClick, width, squared, ...attrs }, ref) => {
		const buttonProps: any = {
			ref: ref,
			disabled: disabled,
			onClick: onClick,
			className: cls(
				type,
				disabled ? "disabled" : "",
				width ? `w-${width}` : "",
				squared ? "squared" : "",
			),
			...attrs,
		};

		if (typeof children === "string") {
			buttonProps["name"] = children;
		}

		return (
			<ButtonStyled
				type={submit ? "submit" : "button"}
				{...buttonProps}
			>
				{children}
			</ButtonStyled>
		);
	},
);

Button.displayName = "Button";

export type { ButtonType };
export default Button;
